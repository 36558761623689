import { ofetch } from 'ofetch'

export default defineNuxtPlugin((_nuxtApp) => {
    globalThis.$fetch = ofetch.create({
        onRequest({ request, options }) {
            if (import.meta.server) {
                if (!options.headers) {
                    options.headers = {}
                }

                const clientIp = _nuxtApp.ssrContext?.event.node.req.headers['x-forwarded-client-ip'] as
                    | string
                    | undefined

                if (clientIp) {
                    options.headers['X-SSR-Request' as keyof HeadersInit] = 'true'
                    options.headers['X-SSR-Client-IP' as keyof HeadersInit] = _nuxtApp.ssrContext?.event.node
                        .req.headers['x-forwarded-client-ip'] as string
                }
            }
        },
    })
})
