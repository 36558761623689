class ScrollObserver {
    entryCallback = (entry: IntersectionObserverEntry) => {
        const handler = this.componentsObserverHandlers.get(entry.target)
        if (handler) {
            handler(entry)
        }
    }

    componentsObserver: IntersectionObserver = new IntersectionObserver((entries) =>
        entries.forEach(this.entryCallback),
    )

    componentsObserverHandlers: Map<Element, (entry: IntersectionObserverEntry) => any> = new Map()

    addTarget(target: Element, callback: (entry: IntersectionObserverEntry) => any) {
        this.componentsObserver.observe(target)
        this.componentsObserverHandlers.set(target, callback)
    }

    remove(target: Element) {
        const handler = this.componentsObserverHandlers.get(target)
        if (handler) {
            this.componentsObserver.unobserve(target)
            this.componentsObserverHandlers.delete(target)
        }
    }
}

export default defineNuxtPlugin(() => {
    return {
        provide: {
            scrollObserver: new ScrollObserver(),
        },
    }
})
