import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import setup_45global from "/opt/buildhome/repo/middleware/setup.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  setup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-required": () => import("/opt/buildhome/repo/middleware/auth-required.ts"),
  "not-auth-required": () => import("/opt/buildhome/repo/middleware/not-auth-required.ts")
}